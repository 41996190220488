/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { FadeLoader } from "react-spinners";

const GlobalLoader = () => {
    return (
        <div className="flex justify-center my-3">
            <FadeLoader className="flex justify-center" 
                         color={'#292828'} 
                         loading="true" 
                         size={100} />
        </div>

    )
}

export default GlobalLoader;