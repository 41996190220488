import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import GlobalLoader from "./components/loaders/global-loader.component.jsx";

const Home = lazy(() => import("./routes/home/home.component.jsx"));
const CoreStructure = lazy(() =>
  import("./routes/core-structure/core-structure.component.jsx")
);
const AboutPage = lazy(() => import("./routes/about/about.component.jsx"));
const ReviewsPage = lazy(() =>
  import("./routes/reviews/reviews.component.jsx")
);
const ProductsPage = lazy(() =>
  import("./routes/product/product.component.jsx")
);
const EventsPage = lazy(() => import("./routes/events/events.component.jsx"));
function App() {
  return (
    <Suspense fallback={<GlobalLoader />}>
      <Routes>
        <Route path="/" element={<CoreStructure />}>
          <Route index element={<Home />} />
          <Route path="about/*" element={<AboutPage />} />
          <Route path="reviews/*" element={<ReviewsPage />} />
          <Route path="products/*" element={<ProductsPage />} />
          <Route path="events/*" element={<EventsPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
